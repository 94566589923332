import DApp from '../config/configuration.js';

const API = {
	async transfer(address,amount){//授权
		let {
			transfer
		} = this.meta;
		// let {
		// 	account
		// } = this;
		let res = await transfer(address,amount)
		return res;
	},
	async approve(address,amount){//授权
		let {
			approve
		} = this.meta;
		let res = await approve(address,amount)
		
		// .send({
		// 	from:account,
		// 	gas:0
		// })
		return res;
	},
	async allowance(owner = null,spender) {//查询授权额度
		let {
			allowance
		} = this.meta;
		let {
			account
		} = this;
		let res = await allowance(owner || account,spender)
		return res.toString();
	},
	async balanceOf(address) {//查询余额
		let {
			balanceOf
		} = this.meta;
		let {
			account
		} = this;
		let res = await balanceOf(address || account)
		return res.toString();
	}
}

export default {
	meta: null,
	account: null,
	web3: null,
	async init(address) {
		let obj = await DApp.erc20Init(address);
		let {
			meta,
			account,
			web3
		} = obj;
		this.meta = meta;
		this.account = account;
		this.web3 = web3;
		return this;
	},
	...API
}