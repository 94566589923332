export default {
    // 授权额度
    approveAmount: '115792089237316195423570985008687907853269984665640564039457584007913129639935',

    chainId:56,

	rpc: 'https://bscrpc.com',

	usdt_address:'0x55d398326f99059fF775485246999027B3197955',

	laf_address:'0x3BEC20CA77e100C50ef0d0066f4c2B348e615F48',

	uniswapV2Router_address:'0x10ED43C718714eb63d5aA57B78B54704E256024E',

	staking_biz_address:'0x3273A291FF46AB09804eA7959c85b75F431E0aAE',

	referral_biz_address:'0xfA4289B3E5dBab53D7f0566361678B600BE34DaB'
}