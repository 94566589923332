import DApp from '../config/configuration.js';

const API = {
    // 获取根用户地址
    async getRootAddress(){
        let {
			getRootAddress
		} = this.meta;
		let res = await getRootAddress()
		return res;
    },
    // 是否绑定推荐人
    async isBindReferral(){
        let {
			isBindReferral
		} = this.meta;
        let {
            account
        } = this;
		let res = await isBindReferral(account)
		return res;
    }
}
export default {
	meta: null,
	account: null,
	web3: null,
	async init() {
		let obj = await DApp.ReferralInit();
		let {
			meta,
			account,
			web3
		} = obj;
		this.meta = meta;
		this.account = account;
		this.web3 = web3;
		return this;
	},
	...API
}