<template>
  <div class="navbar main-p flex jb ac">
    <img src="../assets/logo.png" class="logo" />
    <div class="flex ac">
      <div class="levelTag size-20" v-if="levelName">{{ levelName }}</div>
      <!-- <img src="../assets/img1.png" class="img-60" /> -->
      <img
        src="../assets//lanuage.png"
        class="img-60 ml-20"
        @click.stop="show = !show"
      />
      <div class="ml-20 navbarBtn size-24" @click="connect">{{ address || $t('链接钱包') }}</div>
    </div>
    <div class="lanuage text-c size-24 text-b" :class="show ? 'lanuageShow' : ''" @click.stop>
      <div
        class="pt-15 pb-15"
        :class="current == index ? 'blue' : 'black'"
        v-for="(item, index) in locals"
        :key="item.value"
        @click="tabsClick(index)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { bigToSmall } from "@/utils";
  import { mapGetters } from 'vuex'
export default {
  name: "NavBar",
  data() {
    return {
      show: false,
      levelName:''
    };
  },
  computed: {
    ...mapGetters(['address','staking']),
    locals() {
      return [
        { name: "English", value: "en" },
        { name: "简体中文", value: "zh" },
        { name: "日本語", value: "ja" },
        { name: "한국어", value: "ko" },
        { name: "POCCИЯ", value: "ru" },
      ];
    },
    current(){
      const lang = this.$store.state.user.lang
      const index = this.locals.findIndex(item=>item.value==lang)
      return index
    }
  },
  watch: {
    address: {
      async handler(newVal, oldVal) {
        if (newVal) {
          this.getTeamKpi();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async getTeamKpi(){
      let kpi = await this.staking.getTeamKpi()
      kpi = bigToSmall(kpi)
      console.log('kpi-'+kpi);
      if(kpi<10000)this.levelName = ''
      else if(kpi>=10000 && kpi<50000)this.levelName = 'A'
      else if(kpi>=50000 && kpi<100000)this.levelName = 'B'
      else if(kpi>=100000 && kpi<500000)this.levelName = 'C'
      else if(kpi>=500000 && kpi<1000000)this.levelName = 'D'
      else if(kpi>=1000000)this.levelName = 'E'
      else this.levelName = ''
    },
    connect(){
      this.$store.dispatch('dapp/init')
    },
    tabsClick(index) {
      this.show = false;
      if (this.current == index) return;
      const lang = this.locals[index].value
      this.$i18n.locale = lang
      this.$config.setLang(lang)
      this.$store.commit('user/SET_LANG', lang)
    },
    hideLanuage(){
      this.show = false
    }
  },
};
</script>

<style lang="scss">
.navbar {
  width: 100vw;
  height: 1rem;
  background-color: #000000;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  .logo {
    widows: 1.36rem;
    height: 0.54rem;
  }
  .navbarBtn {
    height: 0.6rem;
    padding: 0 0.3rem;
    background-color: #1362fd;
    line-height: 0.6rem;
    border-radius: 0.3rem;
    font-weight: bold;
  }
  .lanuage {
    width: 2rem;
    padding: 0.15rem 0;
    background-color: #ffffff;
    border-radius: 0.2rem;
    position: absolute;
    top: 1rem;
    right: 1.4rem;
    z-index: 1;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: all 0.2s;
  }
  .lanuageShow {
    transform: scaleY(1);
  }
  .levelTag{
    height: .48rem;
    line-height: .48rem;
    border-radius: .24rem;
    text-align: center;
    background-color: #1F1F1F;
    padding: 0 .2rem;
  }
}
</style>