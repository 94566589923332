const getters = {
    address: state => {
        let str = state.dapp.address
        if (str) {
            return str.slice(0, 6) + '****' + str.slice(str.length - 4)
        }else{
            return ''
        }
    },
    erc20: state => state.dapp.erc20,
    staking: state => state.dapp.staking,
    referral: state => state.dapp.referral,
    uniswapV2Router: state => state.dapp.uniswapV2Router
}
export default getters