import DApp from '../config/configuration.js';
import BigNumber from "bignumber.js";

const API = {
	// 无绑定上级增加资产
	async stake(amount, amountOutMin, stakeIndex) {
		console.log(amount, amountOutMin, stakeIndex);
		
		let {
			stake
		} = this.meta;
		const estimatedGas = await this.meta.estimateGas.stake(amount, amountOutMin, stakeIndex);
		let res = await stake(amount, amountOutMin, stakeIndex,{
			gasLimit:new BigNumber(estimatedGas.toString()).times('1.5').toFixed(0)
		})
		return res;
	},
	// 有绑定上级增加资产
	async stakeWithInviter(amount,amountOutMin,stakeIndex,parent) {
		console.log(amount,amountOutMin,stakeIndex,parent);
		let {
			stakeWithInviter
		} = this.meta;
		const estimatedGas = await this.meta.estimateGas.stakeWithInviter(amount,amountOutMin,stakeIndex,parent);
		let res = await stakeWithInviter(amount,amountOutMin,stakeIndex,parent,{
			gasLimit:new BigNumber(estimatedGas.toString()).times('1.5').toFixed(0)
		})
		return res;
	},
	async unstake(index) {
		let {
			unstake
		} = this.meta;
		const estimatedGas = await this.meta.estimateGas.unstake(index);
		let res = await unstake(index,{
			gasLimit:new BigNumber(estimatedGas.toString()).times('1.5').toFixed(0)
		})
		return res;
	},
	async maxStakeAmount() {
		let {
			maxStakeAmount
		} = this.meta;
		let res = await maxStakeAmount()
		return res.toString();
	},
	async balanceOf(address) {//查询余额
		let {
			balanceOf
		} = this.meta;
		let {
			account
		} = this;
		let res = await balanceOf(address || account)
		return res.toString();
	},
	async getTeamKpi(address) {
		let {
			getTeamKpi
		} = this.meta;
		let {
			account
		} = this;
		let res = await getTeamKpi(address || account)
		return res.toString();
	},
	async rewardOfSlot(index) {
		let {
			rewardOfSlot
		} = this.meta;
		let {
			account
		} = this;
		let res = await rewardOfSlot(account, index)
		return res.toString();
	}
}
export default {
	meta: null,
	account: null,
	web3: null,
	async init() {
		let obj = await DApp.StakingInit();
		let {
			meta,
			account,
			web3
		} = obj;
		this.meta = meta;
		this.account = account;
		this.web3 = web3;
		return this;
	},
	...API
}