<template>
  <div class="popup flex-center" @click="closePop">
    <div class="main-box popupBox" @click.stop v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="text-c size-32 text-b">{{title}}</div>
        <slot></slot>
        <div class="flex jb ac mt-30 size-24 text-b" >
            <div class="popupBtn" @click="closePop">{{$t('取消')}}</div>
            <div class="popupBtn bgBlue" @click="submit">{{$t('确定')}}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"PopupBox",
    props:{
        title:{
            type:String,
            default:''
        },
        loading:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        closePop(){
            if(this.loading)return
            this.$emit('close')
        },
        submit(){
            if(this.loading)return
            this.$emit("submit")
        }
    }
}
</script>

<style lang="scss">
.popup{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    .popupBox{
        width: 5.9rem;
        padding: .3rem .4rem .4rem .4rem;
        border-radius: .4rem;
        transform: scale(0);
        opacity: 0;
        animation: show 0.3s linear forwards;
        background-color: #020E24;
        .popupBtn{
            width: 2.44rem;
            height: .68rem;
            line-height: .68rem;
            border-radius: .2rem;
            text-align: center;
            background-color: #192948;
            overflow: hidden;
        }
    }
}
@keyframes show {
	from {
        opacity: 0;
		transform: scale(0);
	}

	to {
        opacity: 1;
		transform: scale(1);
	}
}
</style>