<template>
  <div class="main-box homeList" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="tabs flex ac">
      <div
        class="size-30 text-b flex-1 text-c"
        :class="current == index ? 'blue' : ''"
        v-for="(item, index) in tabs"
        :key="item.value"
        @click="tabsClick(index)"
      >
        {{ item.name }}
      </div>
      <div class="tabLine" :style="{ left: tabs[current].left }"></div>
    </div>

    <div class="flex ac listTh size-24 text-c pl-20 pr-20">
        <div class="flex-1">{{$t('序号')}}</div>
        <div class="flex-3">{{$t('日期')}}</div>
        <div class="flex-1">{{$t('本金')}}</div>
        <div class="flex-1">{{$t('盈利')}}</div>
        <div class="flex-2">{{$t('赎回进展')}}</div>
    </div>

    <div class="pl-20 pr-20">
      <div v-for="(item,index) in list" :key="index">
        <div class="listTr flex ac size-20 text-c" v-if="(current==0&&item.status==1)||(current==1&&item.status==0)">
          <div class="flex-1">{{ index + 1 }}</div>
          <div class="flex-3">
            <div>{{item.created_at}}</div>
            <div class="mt-10" style="color: #1362FD;" v-if="item.status==1">{{ item.countdownTxt||'--' }}</div>
          </div>
          <div class="flex-1">{{item.amount}}</div>
          <div class="flex-1">{{item.reward}}</div>
          <div class="flex-2 flex-center">
              <div class="listBtn text-b disableBtn" v-if="item.status==0">{{$t('已结束')}}</div>
              <div class="listBtn text-b disableBtn" v-if="item.status==1&& !item.is_unstaking">{{$t('等待赎回')}}</div>
              <div class="listBtn text-b white" v-if="item.status==1&&item.is_unstaking" @click="cancel(item.index)">{{$t('赎回')}}</div>
          </div>
        </div>    
      </div>
        
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HomeList",
  props:{
    list:{
      type: Array,
      default(){
        return []
      }
    }
  },
  data() {
    return {
      current: 0,
      loading: false
    };
  },
  computed:{
    ...mapGetters([
      "staking"
    ]),
    tabs(){
      return [
        { name: this.$t('投资列表'), value: "0", left: "1.4rem" },
        { name: this.$t('赎回列表'), value: "1", left: "4.9rem" },
      ]
    }
  },
  methods: {
    tabsClick(index) {
      if (this.current == index) return;
      this.current = index;
    },
    async cancel(index){
      this.loading = true
      this.staking.unstake(index).then(async result=>{
        await result.wait()
        this.showToast(this.$t('操作成功'), 'success')
        this.loading = false
        this.$emit('refresh')
      }).catch(()=>{
        this.showToast(this.$t('操作失败'), 'error')
        this.loading = false
      })
    }
  },
};
</script>

<style lang="scss">
.homeList {
  border-radius: 0.4rem;
  background: rgba(19,98,253,0.1);
  .tabs {
    height: 1rem;
    position: relative;
    .tabLine {
      width: 0.52rem;
      height: 0.06rem;
      border-radius: 0.03rem;
      background-color: #1362fd;
      position: absolute;
      bottom: 0;
      z-index: 1;
      transition: all 0.3s;
    }
  }

  .listTh {
    height: 0.72rem;
    background: rgba(191, 213, 255, 0.1);
    color: #165498;
  }

  .listTr{
    border-bottom: 0.01rem solid #12213F;
    padding: .3rem 0;
    color: #ACACAC;
    .listBtn{
        border-radius: .12rem;
        padding: .1rem;
        background-color: #1362FD;
    }
    .disableBtn{
      background-color: #111e38 !important;
      color: #455370 !important;
    }
  }
}
</style>