import en from './en.json'
import zh from './zh.json'
import ja from './ja.json'
import ko from './ko.json'
import ru from './ru.json'
export default {
	en,
	zh,
	ja,
	ko,
	ru
}