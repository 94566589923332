import config from '@/config'
const {baseUrl} = config

export default function http(api, params=null, method="GET"){
    let options = {
        method,
        headers:{
            'Content-Type': 'application/json'
        }
    }
    let url = baseUrl + api
    let result = ''
    if(method=='GET'){
        if(params){
            for (const item in params) {
                if(params[item] && String(params[item])){
                    result += `&${item}=${params[item]}`
                }
            }
        }
        if(result)url += '?' + result.slice(1)
    }else{
        if(params){
            options['body'] = JSON.stringify(params)
        }
    }
    return new Promise((resolve,reject)=>{
        fetch(url,options).then(response=>{
            if(response.status==200){
                response.json().then(res=>{
                    resolve(res)
                })
            }else{
                reject()
            }
        })
    })
}