import erc20 from './api/erc20.js'
import staking from './api/staking.js'
import referral from './api/referral.js'
import uniswapV2Router from './api/uniswapV2Router.js'

export default{
	erc20,
	staking,
	referral,
	uniswapV2Router
}