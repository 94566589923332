import dapp from '@/config/dapp'
const { rpc, chainId, usdt_address, laf_address, uniswapV2Router_address, staking_biz_address, referral_biz_address } = dapp

export default {
	erc20_abi: require('../abi/ERC20.json'),
	staking_biz_abi: require('../abi/Staking.json'),
	referral_biz_abi: require('../abi/Referral.json'),
	uniswapV2Router_biz_abi: require('../abi/UniswapV2Router.json'),
	rpc,
	chainId,
	usdt_address,
	laf_address,
	uniswapV2Router_address,
	staking_biz_address,
	referral_biz_address
}
