import DApp from '../config/configuration.js';

const API = {
	async getAmountsOut(amountIn,address) {
		let {
			getAmountsOut
		} = this.meta;
		let res = await getAmountsOut(amountIn,address)
		return res;
	},
}
export default {
	meta: null,
	account: null,
	web3: null,
	async init() {
		let obj = await DApp.UniswapV2RouterInit();
		let {
			meta,
			account,
			web3
		} = obj;
		this.meta = meta;
		this.account = account;
		this.web3 = web3;
		return this;
	},
	...API
}