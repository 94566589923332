/**
 * 混入
 */
export default {
	data() {
		return {
			delayFlag: true
		}
	},
	methods: {
		delayFun(methods, info){
			if(this.delayFlag){
				this.delayFlag = false
				setTimeout(()=> {
					if((info && info !== '') || info ==0)methods(info);
					else methods();
					this.delayFlag = true
				}, 300)
			}
		},
		/**
		 * @description 统一提示框
		 * @param {String} title - 提示内容 
		 * @param {String} [type = null] - 操作后的提示类型 [success | warning | info | error] 
		 * @example
		 * this.showToast('请输入XXX')
		 * @example
		 * this.showToast('操作成功', 'success')
		 * @example
		 * this.showToast('操作失败', 'error')
		 */
        showToast(message, type=null){
			if(type){
				this.$notify({
					title: type,
					type,
					message,
				});
			}else{
				this.$message({
					showClose: true,
					type: 'warning',
					message
				});
			}
        }
	}
}