import config from '@/config';
const { getLang } = config

const state = {
    ref: '',
    lang: getLang()
}

const mutations = {
    SET_REF(state, payload) {
        state.ref = payload
    },
    SET_LANG(state, payload) {
		state.lang = payload
	}
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

