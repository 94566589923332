<template>
  <div class="number-scrolling" :style="{'--font-size': fontSizeStr, '--color': color, '--line-height': lineHeightStr, '--duration': durationStr}">
    <div v-for="(item, index) in numberFormat" class="number-item" :key="index">
      <div class="number-item-inner" :style="{'--index': -numberIndexs[index]}">
        <div v-if="/\d/.test(item)">
          <div v-for="num in 10" :key="num" class="number-item-content">{{num - 1}}</div>
        </div>
        <div v-else>
          <div class="number-item-content">{{item}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { numToMillennials } from '@/components/hbxw-utils/js_sdk/hbxw-utils.js';
  
  export default {
    props: {
      // 数字值
      value: {
        type: Number,
        default: 0
      },
      // 切换过渡时间
      duration: {
        type: [Number, String],
        default: .4
      },
      // 保留的小数点
      decimal: {
        type: Number,
        default: 2
      },
      //  是否要千分位表示法
      isMillennials: {
        type: Boolean,
        default: false
      },
      //  字体大小
      fontSize: {
        type: [String,Number],
        default: 40
      },
      // 字体行高
      lineHeight: {
        type: [String,Number],
        default: 1.2
      },
      // 数字颜色
      color: {
        type: String,
        default: '#333'
      }
    },
    data() {
      return {
        numberIndexs: []
      }
    },
    watch: {
      value(newValue, oldValue) {
        setTimeout(() => {
          this.numberIndexs = this.numberFormat.map((numberItem) => /\d/.test(numberItem) ? numberItem : 0);
        }, 100);
      }
    },
    computed: {
      numberLen() {
        return this.numberFormat.length;
      },
      numberFormat() {
        let numberTemp = this.isMillennials ? numToMillennials(this.value, this.decimal) : Number(this.value).toFixed(this.decimal);
        return String(numberTemp).split('');
      },
      durationStr() {
        if (typeof this.duration === 'number') {
          return this.duration + 's';
        }
        return this.duration;
      },
      fontSizeStr() {
        if (typeof this.fontSize === 'number') {
          return this.fontSize + 'rpx';
        }
        return this.fontSize;
      },
      lineHeightStr() {
        if (typeof this.lineHeight === 'number') {
          return this.lineHeight + 'em';
        }
        return this.fontSize;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .number-scrolling{
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    height: var(--line-height);
  }
  .number-item{
    // width: var(--font-size);
    font-size: var(--font-size);
    height: var(--line-height);
    color: var(--color);
    overflow: hidden;
    position: relative;
    flex: none;
    display: inline-block;
  }
  .number-item-inner{
    font-size: var(--font-size);
    transform: translateY(calc(var(--index) * var(--line-height)));
    transition: transform var(--duration);
  }
  .number-item-content{
    font-size: var(--font-size);
    line-height: var(--line-height);
    height: var(--line-height);
    color: var(--color);
    text-align: center;
  }
</style>
