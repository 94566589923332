/**
 * @description 本地数据存储管理
 */
const setStorage = (key, data) => localStorage.setItem(key, data)
const getStorage = key => localStorage.getItem(key)
const delStorage = key => localStorage.removeItem(key)

// Token
const tokenKey = 'Token'
const setToken = data => setStorage(tokenKey, data)
const getToken = () => getStorage(tokenKey)
const delToken = () => delStorage(tokenKey)

// 多语言
const langKey = 'Lang'
const defaultLang = 'en'
const setLang = data => setStorage(langKey, data)
const getLang = () => getStorage(langKey)||defaultLang
const delLang = () => delStorage(langKey)

// 地址
const addressKey = 'Address'
const setAddress = data => setStorage(addressKey, data)
const getAddress = () => getStorage(addressKey)
const delAddress = () => delStorage(addressKey)

export default {
	setToken,
	getToken,
	delToken,
	
	setLang,
	getLang,
	delLang,
	
	setAddress,
	getAddress,
	delAddress
}